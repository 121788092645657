const messages = {
    home: {
        home: "首頁",
        wallet: "是否連接錢包登錄？",
        LON: "登錄",
        Investment: "投資，就像生活一樣，害怕走錯路。",
        About: "關於我們",
        Ecology: "生態",
        finance: "WLC 金融",
        Pledge: "质押",
        Team: "團隊",
        log2: "登錄",
        log3: "登錄",
        COMPANY: "公司",
        CONTACT: "聯繫我們",
        PARTNER: "合作夥伴",
        Features: "特點",
        Top: "頂尖創作者",
        Partner: "合作夥伴",
        my:'個人'
    },
    homepage: {
        Welcome: "歡迎來到 WLC 生態系統",
        working: "共同努力創造繁榮的未來",
        About: "關於我們",
        watch: "觀看視頻",
        PROPERTY: "財產",
        THE: "該地區最高品質的城市開發商",
        What: "使用了什麼材料？",
        Lorem: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus at tincidunt nisl.",
        EMPOWERING: "通過金融使傳統行業強大",
        By: "通過為實體企業賦能並創造價值，可以實現集團業務和資產的快速發展",
        Learn: "了解更多",
        AGRICULTURE: "農業蓬勃發展",
        DUE: "由於技術",
        Farm: "農場公園項目記錄",
        Partner: "合作夥伴",
        Discover: "了解更多",
        Agronomist: "農學家",
        Lislie: "Lislie Alexander",
        LIVE: "線上博彩",
        CASINO: "現場賭場推動",
        BOUNDARIES: "在線遊戲的界限",
        GAMING: "遊戲",
        Go: "去玩",
        WELCOME: "歡迎來到 WLC 酒店及度假村",
        saff: "一家世界一流的豪華精品酒店品牌，專為尋求隱私、個性化和高品質服務的旅行者而設計，每家酒店位於一流位置。",
        high: "和高品質服務，每家酒店位於一流位置。 一家世界一流的豪華精品酒店品牌。",
        industry: '酒店產業',
        propertyDevelopment: '我们在具有潜力的地方囤地，做房地產開發。我们在電費低廉的地方建設比特幣和以太坊礦場。',
        onlineEntertainment: '線上娛樂',
        gaming: '遊戲，博彩，盤口，我们在賭場合法的地方做博彩業、換匯。',
        cannabisCultivation: '大麻種植',
        islandCannabisCultivation: '海島大麻種植，我们在大麻合法的地方種植、生產、銷售大麻產品。',
        tourism: '旅遊度假',
        luxuryTour: '機票，酒店，遊玩，餐飲豪華遊艇•頂級豪車•私人飛機•海邊別墅 實彈射擊•高爾夫球•主題轟趴•異國風情'
    },
    PledgePage: {
        BLOCKCHAIN: "區塊鏈",
        CRYPTOCURRENCY: "加密貨幣",
        Introduction: "质押規則活動介紹",
        Dive: "深入探索區塊鏈、加密貨幣和比特幣的世界，使用我們全面的平台。在數字金融領域探索、學習並茁壯成長。",
        reliable: "可靠的",
        reputation: "穩定",
        secure: "安全的",
        Calculation: "計算日期",
        computing: "全網算力",
        total: "每日產出",
        output: "累計產出",
        Select: "在下面選擇您所需的裝備",
        JOIN: "授權",
        My: "我的质押",
        Pledge: "质押 WLC",
        CANCEL: "取消",
        PLEDGE: "质押",
        d: '天',
        bsl: '倍算力',
        wdsl: '我的算力',
        astext1:'我的錢包',
        astext2:'最大',
    },
    userpage: {
        Quit: '退出',
        Withdraw: '提現',
        PersonalComputingPower: '個人算力',
        OverlayComputingPower: '疊加算力',
        TotalComputingPower: '算力總值',
        Pledged: '已质押',
        Redeemed: '已赎回',
        DailyOutputOfTheWholeNetwork: '全網每日產出',
        ComputingPowerOfTheDayOfTheWholeNetwork: '全網當天算力',
        PledgeRecord: '质押記錄',
        OverlayRecord: '疊加記錄',
        WithdrawalRecord: '提現記錄',
        ComputingPowerOutputRecord: '算力產出記錄',
        Package: '套餐',
        PledgeTime: '质押時間',
        Account: '帳號',
        PledgeDays: '质押天數',
        PledgeAmount: '质押金額',
        Status: '狀態',
        InProgress: '進行中',
        Release: '解除',
        Subordinate: '下級',
        SubordinateAddress: '下級地址',
        PledgedAmount: '质押金額',
        PledgedDays: '质押天数',
        Reward: '獎勵',
        WithdrawalAmount: '提現金額',
        RechargeTime: '充值時間',
        Operation: '操作',
        Incomplete: '未完成',
        Completed: '已完成',
        Currency: '幣種',
        OutputTime: '產出時間',
        OutputTimeeada: '當前質押',
        Outsf: '當前贖回',
        Outsfdq: '到期',
        sfksa:'請輸入提現金額',
        sfksa2:'詳情',
        yuefgsg:'餘額不足',
        Withdrawal:'提現中',
        safa:'解除质押中',
        Completed2: '已解除',
        Completed6: '下級地址',
        Completed3: '推薦人獎勵',
        Completed4: '下級地址'
      },
      teampage: {
        InvitationCode: '邀請碼',
        DirectReferrals: '直推人數',
        IndirectReferrals: '間推人數',
        TotalTeamMembers: '團隊總人數',
        MyTeam: '我的團隊',
        Unit: '代',
        People: '人'
      },
      jstext: {
        TokenImportError: '代币导入错误',
        ConfirmPledge: '请确认质押',
        AuthorizationTakesTime: '授权需要一定时间，请耐心等待',
        Confirm: '确认',
        Authorizing: '授权中',
        AuthorizationSuccessful: '授权成功',
        AuthorizationFailed: '授权失败',
        Authorized: '已授权',
        PledgePaymentInProgress: '质押支付中',
        PledgeSuccessful: '质押成功',
        InsufficientBalanceOrTokenNotImported: '余额不足或網絡錯誤',
        PleaseSign: '请签名',
        PleaseEnterInvitationCode: '请输入邀请码',
        InvitationCodeDoesNotExist: '邀请码不存在',
        LoggingIn: '登陆中',
        Registering: '注册中',
        PleaseSign2: '请签名',
      }
      
}

export default messages
