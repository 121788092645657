const messages = {
    home: {
        home: "홈",
        wallet: "지갑에 연결하여 로그인 하시겠습니까?",
        LON: "로그인",
        Investment: "투자는 인생처럼, 잘못된 길을 걸을까 두려워합니다.",
        About: "회사 소개",
        Ecology: "생태계",
        finance: "WLC 금융",
        Pledge: "서약",
        Team: "팀",
        log2: "로그인",
        log3: "로그인",
        COMPANY: "회사",
        CONTACT: "연락처",
        PARTNER: "파트너",
        Features: "특징",
        Top: "최고 창조자",
        Partner: "파트너",
        my:'개인'
    },
    homepage: {
        Welcome: "WLC 생태계에 오신 것을 환영합니다",
        working: "번영한 미래를 창조하기 위해 함께 일합니다",
        About: "우리에 대해",
        watch: "비디오 시청",
        PROPERTY: "재산",
        THE: "해당 지역에서 최고 품질의 도시 개발업자",
        What: "어떤 재료를 사용하나요?",
        Lorem: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus at tincidunt nisl.",
        EMPOWERING: "재무로 전통 산업을 강화",
        By: "실체 기업에 가치를 부여하고 촉진함으로써 그룹 비즈니스와 자산의 신속한 발전을 이룰 수 있습니다",
        Learn: "더 알아보기",
        AGRICULTURE: "농업 번영",
        DUE: "기술로 인해",
        Farm: "농장 공원 프로젝트 기록",
        Partner: "파트너",
        Discover: "더 알아보기",
        Agronomist: "농업가",
        Lislie: "리즐리 알렉산더",
        LIVE: "라이브 카지노",
        CASINO: "라이브 카지노는",
        BOUNDARIES: "온라인 게임의 경계를",
        GAMING: "밀어올립니다",
        Go: "가서 놀다",
        WELCOME: "WLC 호텔 & 리조트에 오신 것을 환영합니다",
        saff: "개인 정보, 맞춤 서비스 및 고품질 서비스를 찾는 여행자들을 위해 설계된 세계적 수준의 고급 부티크 호텔 브랜드로, 각 호텔은 일류 위치에 있습니다.",
        high: "및 고품질 서비스, 각 호텔은 일류 위치에 있습니다. 세계적 수준의 고급 부티크 호텔 브랜드입니다.",
        industry: '호텔 산업',
        propertyDevelopment: '유망한 지역에서 토지를 확보하고 부동산 개발을 진행합니다. 전기 요금이 낮은 지역에 비트코인 및 이더리움 채굴장을 건설합니다.',
        onlineEntertainment: '온라인 엔터테인먼트',
        gaming: '게임, 도박, 배당금 우리는 카지노가 합법인 곳에서 도박 및 환전을 진행합니다.',
        cannabisCultivation: '대마 재배',
        islandCannabisCultivation: '섬 대마 재배, 대마가 합법인 곳에서 대마 제품을 재배, 생산 및 판매합니다.',
        tourism: '관광 및 휴가',
        luxuryTour: '항공권, 호텔, 관광, 식사 고급 요트•최고급 차량•사설 비행기•해변 빌라 실탄 사격•골프•테마 파티•이국적인 관습'
      },
      PledgePage: {
        BLOCKCHAIN: "블록체인",
        CRYPTOCURRENCY: "암호화폐",
        Introduction: "담보 규칙 활동 소개",
        Dive: "블록체인, 암호화폐 및 비트코인의 세계로 빠져들어 전체적인 플랫폼을 통해 탐험하고 배우며 디지털 금융의 영역에서 번성하십시오.",
        reliable: "신뢰할 수 있는",
        reputation: "평판",
        secure: "안전한",
        Calculation: "계산 날짜",
        computing: "총 네트워크 컴퓨팅 파워",
        total: "일일 생산량",
        output: "총 생산량",
        Select: "아래에서 원하는 장비를 선택하세요",
        JOIN: "지금 가입",
        My: "내 지갑",
        Pledge: "WLC 담보",
        CANCEL: "취소",
        PLEDGE: "담보",
        d: "일",
        bsl: "배 계산 파워",
        wdsl:'나의 컴퓨팅 파워',
        astext1:'내 지갑',
        astext2:'최대',
        
      },
      userpage: {
        Quit: '나가기',
        Withdraw: '인출',
        PersonalComputingPower: '개인 컴퓨팅 파워',
        OverlayComputingPower: '덮어쓰기 컴퓨팅 파워',
        TotalComputingPower: '총 컴퓨팅 파워',
        Pledged: '담보',
        Redeemed: '상환됨',
        DailyOutputOfTheWholeNetwork: '전체 네트워크의 일일 출력',
        ComputingPowerOfTheDayOfTheWholeNetwork: '전체 네트워크의 하루 동안의 컴퓨팅 파워',
        PledgeRecord: '담보 기록',
        OverlayRecord: '덮어쓰기 기록',
        WithdrawalRecord: '인출 기록',
        ComputingPowerOutputRecord: '컴퓨팅 파워 출력 기록',
        Package: '패키지',
        PledgeTime: '담보 시간',
        Account: '계정',
        PledgeDays: '담보 일수',
        PledgeAmount: '담보 금액',
        Status: '상태',
        InProgress: '진행 중',
        Release: '해제',
        Subordinate: '하위',
        SubordinateAddress: '하위 주소',
        PledgedAmount: '담보 금액',
        PledgedDays: '담보 일수',
        Reward: '보상',
        WithdrawalAmount: '인출 금액',
        RechargeTime: '충전 시간',
        Operation: '작업',
        Incomplete: '미완료',
        Completed: '완료됨',
        Currency: '통화',
        OutputTime: '출력 시간',
        OutputTimeeada: '현재 담보',
        Outsf: '현재 상환됨',
        Outsfdq: '만료',
        sfksa:'출금 금액을 입력하십시오',
        sfksa2:'세부 정보',
        yuefgsg:'잔액 부족',
        Withdrawal:'인출 중',
        safa:'언스테이킹 진행 중',
        Completed2: '해제됨',
        Completed6: '하부 주소',
        Completed3: '추천인 보상',
        Completed4: '하부 주소'
      },
      teampage: {
        InvitationCode: '초대 코드',
        DirectReferrals: '직접 추천',
        IndirectReferrals: '간접 추천',
        TotalTeamMembers: '총 팀원',
        MyTeam: '나의 팀',
        Unit: '대',
        People: '명'
      },
      jstext: {
        TokenImportError: '토큰 가져 오기 오류',
        ConfirmPledge: '플레지를 확인하십시오',
        AuthorizationTakesTime: '인증에는 시간이 걸릴 수 있으니 기다려주십시오',
        Confirm: '확인',
        Authorizing: '승인 중',
        AuthorizationSuccessful: '승인 성공',
        AuthorizationFailed: '승인 실패',
        Authorized: '승인 완료',
        PledgePaymentInProgress: '플레지 결제 진행 중',
        PledgeSuccessful: '플레지 성공',
        InsufficientBalanceOrTokenNotImported: '잔액 부족 또는 토큰 가져 오기 실패',
        PleaseSign: '서명하세요',
        PleaseEnterInvitationCode: '초대 코드를 입력하십시오',
        InvitationCodeDoesNotExist: '초대 코드가 존재하지 않습니다',
        LoggingIn: '로그인 중',
        Registering: '등록 중',
        PleaseSign2: '서명하세요',
      }
}

export default messages