import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    children: [
      {
        path: "/HomePage",
        component: () => import("@/views/home/HomePage.vue"),
        name: 'HomePage'
      },
      {
        path: "/UserPage",
        component: () => import("@/views/home/UserPage.vue"),
        name: 'UserPage'
      },
      {
        path: "/PledgePage",
        component: () => import("@/views/home/PledgePage.vue"),
        name: 'PledgePage'
      },
      {
        path: "/TeamPage",
        component: () => import("@/views/home/TeamPage.vue"),
        name: 'TeamPage'
      },
      {
        path: "/",
        redirect: "/HomePage"
      },
    ]
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 }
  }
})

export default router
