const messages = {
  home: {
    home: 'Home',
    wallet: 'Whether to connect to wallet login or not?',
    LON: 'LON IN',
    Investment: 'Investment, like life, is afraid of taking the wrong path.',
    About: 'About us',
    Ecology: 'Ecology',
    finance: 'WLC finance',
    Pledge: 'Pledge',
    Team: 'Team',
    log2: 'log on',
    log3: 'log in',
    COMPANY: 'COMPANY',
    CONTACT: 'CONTACT US',
    PARTNER: 'PARTNER',
    Features: 'Features',
    Top: 'Top Creator',
    Partner: 'Partner',
    my: 'Personal'
  },
  homepage: {
    Welcome: 'Welcome to the WLC ecosystem',
    working: 'working together to create a prosperous future',
    About: 'About us',
    watch: 'watch video',
    PROPERTY: 'PROPERTY',
    THE: 'THE HIGHEST QUALITY URBANDEVELOPER IN THE REGION',
    What: 'What materials are used?',
    Lorem: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus at tincidunt nisl.',
    EMPOWERING: 'EMPOWERING TRADITIONAL INDUSTRIES WITH FINANCE',
    By: 'By empowering and creating value for physical enterprises,the rapid development of group business and assets can be achieved',
    Learn: 'Learn more',
    AGRICULTURE: 'AGRICULTURE THRIVES',
    DUE: 'DUE TO TECHNOLOGY',
    Farm: 'Farm Park Project Record',
    Partner: 'Partner',
    Discover: 'Discover More',
    Agronomist: 'Agronomist',
    Lislie: 'Lislie Alexander',
    LIVE: 'LIVE CASINO',
    CASINO: 'LIVE CASINO PUSHES THE',
    BOUNDARIES: 'BOUNDARIES IN ONLINE',
    GAMING: 'GAMING',
    Go: 'Go and play',
    WELCOME: 'WELCOME TO THE WLC HOTEL & RESORT',
    saff: 'A world-class luxury boutique hotel brand designed specifically for travelers seeking privacy, personalization, and high-quality service, with each hotel located in a first-class location.',
    high: 'and high-quality service, with each hotel located in a first-class location. A worldclass luxury boutique hotel brand.',
    industry: 'Hotel Industry',
    propertyDevelopment: 'We acquire land in promising areas and engage in real estate development. We build Bitcoin and Ethereum mines in areas with low electricity costs.',
    onlineEntertainment: 'Online Entertainment',
    gaming: 'Games, gambling, odds, we engage in gambling and currency exchange in places where casinos are legal.',
    cannabisCultivation: 'Cannabis Cultivation',
    islandCannabisCultivation: 'Island cannabis cultivation, we cultivate, produce, and sell cannabis products in places where cannabis is legal.',
    tourism: 'Tourism and Vacation',
    luxuryTour: 'Air tickets, hotels, sightseeing, dining Luxurious yachts•Top luxury cars•Private jets•Beach villas Live ammunition shooting•Golf•Theme parties•Exotic customs'
  },
  PledgePage: {
    BLOCKCHAIN: "Blockchain",
    CRYPTOCURRENCY: "Cryptocurrency",
    Introduction: "Introduction to Pledge Rules Activity",
    Dive: "Dive into the world of blockchain, cryptocurrency, and bitcoin with our comprehensive platform. Explore, learn, and thrive in the realm of digital finance.",
    reliable: "Reliable",
    reputation: "Reputation",
    secure: "Secure",
    Calculation: "Calculation Date",
    computing: "Total Network Computing Power",
    total: "Daily Output",
    output: "Total Output",
    Select: "Select your desired equipment below",
    JOIN: "ACCREDIT",
    My: "My Wallet",
    Pledge: "Pledge WLC",
    CANCEL: "Cancel",
    PLEDGE: "Pledge",
    d: "d",
    bsl: "Times",
    wdsl: 'My Computing Power',
    astext1:'My Wallet',
    astext2:'Max',
  },
  userpage: {
    Quit: 'Quit',
    Withdraw: 'Withdraw',
    PersonalComputingPower: 'Personal Computing Power',
    OverlayComputingPower: 'Overlay Computing Power',
    TotalComputingPower: 'Total Computing Power',
    Pledged: 'Pledged',
    Redeemed: 'Redeemed',
    DailyOutputOfTheWholeNetwork: 'Daily Output Of The Whole Network',
    ComputingPowerOfTheDayOfTheWholeNetwork: 'Computing Power Of The Day Of The Whole Network',
    PledgeRecord: 'Pledge Record',
    OverlayRecord: 'Overlay Record',
    WithdrawalRecord: 'Withdrawal Record',
    ComputingPowerOutputRecord: 'Computing Power Output Record',
    Package: 'Package',
    PledgeTime: 'Pledge Time',
    Account: 'Account',
    PledgeDays: 'Pledge Days',
    PledgeAmount: 'Pledge Amount',
    Status: 'Status',
    InProgress: 'In Progress',
    Release: 'Release',
    Subordinate: 'Subordinate',
    SubordinateAddress: 'Subordinate Address',
    PledgedAmount: 'Pledged Amount',
    PledgedDays: 'Pledged Days',
    Reward: 'Reward',
    WithdrawalAmount: 'Withdrawal Amount',
    RechargeTime: 'Recharge Time',
    Operation: 'Operation',
    Incomplete: 'Incomplete',
    Completed: 'Completed',
    Currency: 'Currency',
    OutputTime: 'Output Time',
    OutputTimeeada: 'Current Pledge',
    Outsf: 'Current Redeemed',
    Outsfdq: 'Due',
    sfksa: 'Please enter the withdrawal amount',
    sfksa2: 'Details',
    yuefgsg: 'Insufficient balance',
    Withdrawal: 'Withdrawal in progress',
    safa: 'Unstaking in progress',
    Completed2: 'Released',
    Completed6: 'Subordinate Address',
    Completed3: 'Referrer Reward',
    Completed4: 'Subordinate Address'
  },
  teampage: {
    InvitationCode: 'Invitation Code',
    DirectReferrals: 'Direct Referrals',
    IndirectReferrals: 'Indirect Referrals',
    TotalTeamMembers: 'Total Team Members',
    MyTeam: 'My Team',
    Unit: 'Unit',
    People: 'People'
  },
  jstext: {
    TokenImportError: 'Token import error',
    ConfirmPledge: 'Please confirm pledge',
    AuthorizationTakesTime: 'Authorization takes time, please be patient',
    Confirm: 'Confirm',
    Authorizing: 'Authorizing',
    AuthorizationSuccessful: 'Authorization successful',
    AuthorizationFailed: 'Authorization failed',
    Authorized: 'Authorized',
    PledgePaymentInProgress: 'Pledge payment in progress',
    PledgeSuccessful: 'Pledge successful',
    InsufficientBalanceOrTokenNotImported: 'Insufficient balance or token not imported',
    PleaseSign: 'Please sign',
    PleaseEnterInvitationCode: 'Please enter invitation code',
    InvitationCodeDoesNotExist: 'Invitation code does not exist',
    LoggingIn: 'Logging in',
    Registering: 'Registering',
    PleaseSign2: 'Please sign',
  }
}

export default messages