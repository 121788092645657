const messages = {
    home:{
        home: "หน้าแรก",
        wallet: "ต้องการเชื่อมต่อกระเป๋าสตางค์เพื่อเข้าสู่ระบบหรือไม่?",
        LON: "เข้าสู่ระบบ",
        Investment: "การลงทุน เหมือนกับชีวิต กลัวที่จะเลือกทางผิด",
        About: "เกี่ยวกับเรา",
        Ecology: "นิเวศวิทยา",
        finance: "การเงิน WLC",
        Pledge: "สัญญา",
        Team: "ทีมงาน",
        log2: "เข้าสู่ระบบ",
        log3: "เข้าสู่ระบบ",
        COMPANY: "บริษัท",
        CONTACT: "ติดต่อเรา",
        PARTNER: "พาร์ทเนอร์",
        Features: "คุณสมบัติ",
        Top: "ผู้สร้างยอดเยี่ยม",
        Partner: "พาร์ทเนอร์",
        my:'บุคคล'
      },
      homepage: {
        Welcome: "ยินดีต้อนรับสู่ระบบนิเวศ WLC",
        working: "ทำงานร่วมกันเพื่อสร้างอนาคตที่รุ่งโรจน์",
        About: "เกี่ยวกับเรา",
        watch: "รับชมวิดีโอ",
        PROPERTY: "ทรัพย์สิน",
        THE: "นักพัฒนาเมืองที่มีคุณภาพสูงสุดในภูมิภาค",
        What: "วัสดุที่ใช้มีอะไรบ้าง?",
        Lorem: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus at tincidunt nisl.",
        EMPOWERING: "การเสริมสร้างอุตสาหกรรมด้วยการเงิน",
        By: "โดยการเสริมสร้างและสร้างค่าสำหรับองค์กรทางกายภาพ สามารถทำให้องค์กรธุรกิจและสินทรัพย์เจริญเร็วได้",
        Learn: "เรียนรู้เพิ่มเติม",
        AGRICULTURE: "เกษตรเจริญรุ่งเรือง",
        DUE: "เนื่องจากเทคโนโลยี",
        Farm: "บันทึกโครงการสวนสัตว์",
        Partner: "พันธมิตร",
        Discover: "ค้นพบเพิ่มเติม",
        Agronomist: "นักวิชาการเกษตร",
        Lislie: "ลิสลี อเล็กซานเดอร์",
        LIVE: "คาสิโนสด",
        CASINO: "คาสิโนสดขับเคลื่อน",
        BOUNDARIES: "ขีดจำกัดในการเล่นเกมออนไลน์",
        GAMING: "เล่นไปเลย",
        Go: "ไปเล่น",
        WELCOME: "ยินดีต้อนรับสู่โรงแรมและรีสอร์ท WLC",
        saff: "แบรนด์โรงแรมบูติคหรูระดับโลกที่ออกแบบมาเพื่อนักเดินทางที่ต้องการความเป็นส่วนตัว การปรับแต่งส่วนตัว และบริการคุณภาพสูง โดยที่ทุกๆ โรงแรมตั้งอยู่ในสถานที่ระดับต้นๆ",
        high: "และบริการคุณภาพสูง โดยที่ทุกๆ โรงแรมตั้งอยู่ในสถานที่ระดับต้นๆ แบรนด์โรงแรมบูติคหรูระดับโลก",
        industry: 'อุตสาหกรรมโรงแรม',
        propertyDevelopment: 'เราจะซื้อที่ดินในพื้นที่ที่มีศักยภาพและเข้ามาพัฒนาอสังหาริมทรัพย์ เราจะสร้างเหมือง Bitcoin และ Ethereum ในพื้นที่ที่มีค่าไฟฟ้าต่ำ',
        onlineEntertainment: 'บันเทิงออนไลน์',
        gaming: 'เกม การพนัน ออด เราจะมีการพนันและแลกเปลี่ยนเงินตราในที่ที่มีการคาสิโนถูกกฏหมาย',
        cannabisCultivation: 'การเพาะปลูกกัญชา',
        islandCannabisCultivation: 'การเพาะปลูกกัญชาบนเกาะ เราจะเพาะปลูกผลิตและขายผลิตภัณฑ์กัญชาในที่ที่กัญชาถูกกฏหมาย',
        tourism: 'การท่องเที่ยวและการพักผ่อน',
        luxuryTour: 'ตั๋วเครื่องบิน โรงแรม การเที่ยวชม อาหารหรู ยอร์ทหรู•รถหรูสุด•เครื่องบินส่วนตัว•วิลล่าติดชายหาด การยิงกระสุนจริง•กอล์ฟ•ปาร์ตี้ธีม•ประเพณีที่หลากหลาย'
      },
      PledgePage: {
        BLOCKCHAIN: "บล็อกเชน",
        CRYPTOCURRENCY: "เงินดิจิทัล",
        Introduction: "การแนะนำกิจกรรมกฎการค้ำประกัน",
        Dive: "ลงไปสำรวจโลกของบล็อกเชน สกุลเงินดิจิทัล และบิตคอยน์ด้วยแพลตฟอร์มของเราที่ครอบคลุมทั้งหมด สำรวจ เรียนรู้ และริเริ่มการเจริญเติบโตในดินแดนของการเงินดิจิทัล",
        reliable: "เชื่อถือได้",
        reputation: "ชื่อเสียง",
        secure: "ปลอดภัย",
        Calculation: "วันที่คำนวณ",
        computing: "พลังการคำนวณของเครือข่ายทั้งหมด",
        total: "ผลผลิตรวมต่อวัน",
        output: "ผลผลิตทั้งหมด",
        Select: "เลือกอุปกรณ์ที่คุณต้องการด้านล่าง",
        JOIN: "เข้าร่วมตอนนี้",
        My: "กระเป๋าเงินของฉัน",
        Pledge: "ค้ำประกัน WLC",
        CANCEL: "ยกเลิก",
        PLEDGE: "ค้ำประกัน",
        d: "วัน",
        bsl: "ความสามารถในการคำนวณครั้ง",
        wdsl:'พลังการคำนวณของฉัน',
        astext1:'กระเป๋าเงินของฉัน',
        astext2:'สูงสุด',
      },
      userpage: {
        Quit: 'ออก',
        Withdraw: 'ถอน',
        PersonalComputingPower: 'พลังคำนวณส่วนบุคคล',
        OverlayComputingPower: 'พลังคำนวณทับซ้อน',
        TotalComputingPower: 'พลังคำนวณรวม',
        Pledged: 'ทำสัญญา',
        Redeemed: 'ได้รับคืนแล้ว',
        DailyOutputOfTheWholeNetwork: 'ผลผลิตรายวันของเครือข่ายทั้งหมด',
        ComputingPowerOfTheDayOfTheWholeNetwork: 'พลังคำนวณของเครือข่ายทั้งหมดในวันนั้น',
        PledgeRecord: 'บันทึกการทำสัญญา',
        OverlayRecord: 'บันทึกการทับซ้อน',
        WithdrawalRecord: 'บันทึกการถอน',
        ComputingPowerOutputRecord: 'บันทึกผลผลิตพลังคำนวณ',
        Package: 'แพ็กเกจ',
        PledgeTime: 'เวลาทำสัญญา',
        Account: 'บัญชี',
        PledgeDays: 'จำนวนวันที่ทำสัญญา',
        PledgeAmount: 'จำนวนเงินที่ทำสัญญา',
        Status: 'สถานะ',
        InProgress: 'กำลังดำเนินการ',
        Release: 'ปล่อย',
        Subordinate: 'ใต้บังคับบัญชา',
        SubordinateAddress: 'ที่อยู่ใต้บังคับบัญชา',
        PledgedAmount: 'จำนวนเงินที่ทำสัญญา',
        PledgedDays: 'จำนวนวันที่ทำสัญญา',
        Reward: 'รางวัล',
        WithdrawalAmount: 'จำนวนเงินที่ถอน',
        RechargeTime: 'เวลาเติมเงิน',
        Operation: 'การดำเนินการ',
        Incomplete: 'ยังไม่เสร็จสมบูรณ์',
        Completed: 'เสร็จสมบูรณ์',
        Currency: 'สกุลเงิน',
        OutputTime: 'เวลาผลลิต',
        OutputTimeeada: 'จำนวนสัญญาปัจจุบัน',
        Outsf: 'จำนวนสัญญาที่ได้รับคืนแล้ว',
        Outsfdq: 'วันที่หมดอายุ',
        sfksa:'โปรดป้อนยอดถอน',
        sfksa2:'รายละเอียด',
        yuefgsg:'ยอดเงินไม่เพียงพอ',
        Withdrawal:'การถอนเงินกำลังดำเนินการ',
        safa:'การถอนการจำนำกำลังดำเนินการ',
        Completed2: 'ถูกยกเลิกแล้ว',
        Completed6: 'ที่อยู่ย่อย',
        Completed3: 'รางวัลผู้แนะนำ',
        Completed4: 'ที่อยู่ย่อย'
      },
      teampage: {
        InvitationCode: 'รหัสเชิญ',
        DirectReferrals: 'การแนะนำโดยตรง',
        IndirectReferrals: 'การแนะนำทางอ้อม',
        TotalTeamMembers: 'จำนวนสมาชิกทั้งหมดในทีม',
        MyTeam: 'ทีมของฉัน',
        Unit: 'หน่วย',
        People: 'คน'
      },
      jstext: {
        TokenImportError: 'ข้อผิดพลาดในการนำเข้าโทเค็น',
        ConfirmPledge: 'โปรดยืนยันการมัดจำ',
        AuthorizationTakesTime: 'การให้อำนาจอาจใช้เวลาสักครู่ โปรดอดทน',
        Confirm: 'ยืนยัน',
        Authorizing: 'การอนุมัติกำลังดำเนินการ',
        AuthorizationSuccessful: 'การอนุมัติสำเร็จ',
        AuthorizationFailed: 'การอนุมัติล้มเหลว',
        Authorized: 'ได้รับอนุญาตแล้ว',
        PledgePaymentInProgress: 'การชำระเงินมัดจำกำลังดำเนินการ',
        PledgeSuccessful: 'การมัดจำสำเร็จ',
        InsufficientBalanceOrTokenNotImported: 'ยอดเงินไม่เพียงพอหรือไม่ได้นำเข้าโทเค็น',
        PleaseSign: 'โปรดเซ็นชื่อ',
        PleaseEnterInvitationCode: 'โปรดป้อนรหัสเชิญ',
        InvitationCodeDoesNotExist: 'ไม่มีรหัสเชิญ',
        LoggingIn: 'เข้าสู่ระบบ',
        Registering: 'ลงทะเบียน',
        PleaseSign2: 'โปรดเซ็นชื่อ',
      }
}

export default messages