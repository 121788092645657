<template>
  <router-view />
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useStore } from "vuex"
const router = useRouter();
const store = useStore();
function mong() {
  if (window.ethereum) {
    window.ethereum.on('accountsChanged', function () {
      localStorage.removeItem('token');
      localStorage.removeItem('inviteCode');
      sessionStorage.clear();
      store.commit('isdengluzhang', false);
      store.commit('isszhuce', false);
      router.push({
        path: '/HomePage',
      });
    });
  }
}
mong()
const debounce = (fn, delay) => {

  let timer = null;

  return function () {

    let context = this;

    let args = arguments;

    clearTimeout(timer);

    timer = setTimeout(function () {

      fn.apply(context, args);

    }, delay);

  }

}


const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {

  constructor(callback) {

    callback = debounce(callback, 16);

    super(callback);

  }

}
</script>

<style lang="scss">
body, html {
  overscroll-behavior: none;
}
.esfskm{
    height:50px;
}</style>
