import { createStore } from 'vuex'

export default createStore({
  state: {
    wclAddress: '0x0887Ac8023681eCE9a02282A7Ad2F5439429E3E6',// wcl代币合约地址
    usdtAddress: '0x55d398326f99059fF775485246999027B3197955',// usdt代币合约地址
    pledgeAddress: '0x2625Eed4910FE29D1fD775905d58012B5534a3c2',// 质押合约地址
    Separateaccount: '0x8A99ecB84D8202749564823712cBc1d10D8a63f5',// 分账合约地址
    otherDWLC: '0xABE789AbeDaA96CB3ef705B53ec97F47082939f5',// 推荐人记录合约地址
    Addre: '0x38',// 测试ST代币合约地址
    dengluzhang: false,//是否登录
    szhuce: false,//是否注册
    usdt: 0,
    wcl: 0,
  },
  getters: {

  },
  mutations: {
    isdengluzhang(state, i) {//登录状态改变
      state.dengluzhang = i
    },
    isszhuce(state, i) {//注册状态改变
      state.szhuce = i
    },
    Message(state, { usdt }) {
      state.usdt = usdt
    },
  },
  actions: {
  },
  modules: {
  }
})
